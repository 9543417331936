/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is set up
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const ListResearchGroups = () => {
  const [researchGroups, setResearchGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [localIP, setLocalIP] = useState('localhost');
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Logout API
      localStorage.removeItem('access_token'); // Remove access token
      navigate('/login'); // Redirect to login
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  // Handle deleting a trivia group
  const handleDeleteGroup = async (group_uuid) => {
    try {
      const response = await api.delete(`http://${localIP}:5001/delete_research_group/${group_uuid}`); // Adjust the port as per your backend server

      if (response.status === 200) {
        // Navigate back to the list regardless of the confirmation response
        setResearchGroups((prevGroups) => prevGroups.filter(group => group.group_uuid !== group_uuid));
        setSuccessMessage('Group deleted successfully.');
      }
    } catch (error) {
      setSuccessMessage('');
      // Handle error
      if (error.response) {
        setErrorMessage(`Error: ${error.response.data.message}`); // Display error from response
      } else {
        setErrorMessage('Error: Failed to delete the group');
      }
    }
  };

  // Copy link to clipboard
  const handleCopyLink = (group_uuid) => {
    const link = `http://${localIP}/startResearchGroupPoll/${group_uuid}`;

    // Check if clipboard API is available
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((error) => {
          console.error('Failed to copy link: ', error);
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert('Link copied to clipboard!');
        } else {
          alert('Failed to copy link');
        }
      } catch (error) {
        console.error('Fallback: Oops, unable to copy', error);
      }

      document.body.removeChild(textArea);
    }
  };

  // Fetch research polls
  useEffect(() => {
    const fetchResearchGroups = async () => {
      try {
        setErrorMessage('');
        const response = await api.get('/research_groups'); // Fetch research polls
        setResearchGroups(response.data.groups); // Set the polls in state
        setLocalIP(response.data.localIP);
        setErrorMessage('');
      } catch (error) {
        handleErrors(error, navigate, fetchResearchGroups, setErrorMessage);
      }
    };

    fetchResearchGroups(); // Call on component mount
  }, [navigate]);

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Research Polls</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Your Research Groups</h2>

        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

        {researchGroups.length === 0 ? (
          <p>No research groups available.</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {researchGroups.map((group) => (
              <li key={group.group_uuid} style={{ marginBottom: '20px' }}>
                <p>Group Name: {group.group_name}</p>
                <p>Group Title: {group.group_title}</p>
                <p>Group Description: {group.group_desc}</p>
                <p>Group ID: {group.group_uuid}</p>
                <p>Created On: {new Date(group.created_at).toLocaleString()}</p>
                <p>Number of Polls: {group.num_polls}</p>

                {group.num_polls > 0 ? (
                  <>
                    {/* Start Research Group Poll and Copy Link */}
                    <a
                      href={`https://${localIP}/startResearchGroupPoll/${group.group_uuid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#fff',
                        backgroundColor: '#007BFF', // Bootstrap primary color
                        border: 'none',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        textAlign: 'center'
                      }}
                    >
                      Start Research Group Poll
                    </a>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      <input
                        type="text"
                        readOnly
                        value={`https://${localIP}/startResearchGroupPoll/${group.group_uuid}`}
                        style={{ flexGrow: 1, marginRight: '10px' }}
                      />
                      <button onClick={() => handleCopyLink(group.group_uuid)}>Copy Link</button>
                    </div>
                  </>
                ) : (
                  <button
                    onClick={() => navigate('/manageResearchGroupPolls')}
                    style={{
                      display: 'inline-block',
                      padding: '10px 20px',
                      fontSize: '16px',
                      color: '#fff',
                      backgroundColor: '#28a745', // Green color
                      border: 'none',
                      borderRadius: '5px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      marginTop: '10px'
                    }}
                  >
                    Manage Research Group Polls
                  </button>
                )}

                {/* Delete Group Button */}
                <button
                  onClick={() => handleDeleteGroup(group.group_uuid)}
                  style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: '#fff',
                    backgroundColor: '#FF0000', // Red for delete button
                    border: 'none',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}
                >
                  Delete Group
                </button>

                {/* Display error message if deletion fails */}
                {errorMessage && (
                  <p style={{ color: 'red', marginTop: '10px' }}>
                    {errorMessage}
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}

      </div>
    </div>
  );
};

export default withAuthCheck(ListResearchGroups);
