/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // To navigate back to login after reset
import api from '../api'; // Assuming you have an Axios instance set up
import '../App.css';
import Sidebar from '../utils/Sidebar';
import handleErrors from '../auth/handleErrors';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/forgot_password', { email }); // Flask endpoint
            setSuccessMessage(response.data.message || 'Reset email sent successfully.');
            setErrorMessage('');
        } catch (error) {
            setSuccessMessage('');
            handleErrors(error, navigate, null, setErrorMessage);
        }
    };

    return (
        <div className="container">
            <Sidebar />
            <div className="main-content">
                <h2>Forgot Password</h2>
                <p>Enter your email below to receive a password reset link.</p>
                <form onSubmit={handleResetPassword}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={{ display: 'block', marginBottom: '10px' }}
                    />
                    <button type="submit" style={{ display: 'block', marginBottom: '10px' }}>
                        Reset Password
                    </button>
                </form>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                <button onClick={() => navigate('/login')} style={{ display: 'block', marginTop: '20px' }}>
                    Back to Login
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;