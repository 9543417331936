import api from '../api'; // Your Axios instance

export const refreshToken = async () => {
  try {
    const response = await api.post('/refresh_token', {}, { withCredentials: true });
    const newAccessToken = response.data.access_token;
    localStorage.setItem('access_token', newAccessToken); // Store the new access token
    return newAccessToken;
  } catch (error) {
    console.error('Refresh token failed:', error);
    return null; // Return null if refresh fails
  }
};

export const isLoggedIn = async () => {
  try {
      // Get all cookies from document.cookie
      const cookies = document.cookie.split(';');
      
      // Find the access_token cookie
      const tokenCookie = cookies.find(cookie => cookie.trim().startsWith('access_token_cookie='));
      
      if (!tokenCookie) {
          console.log('Token not found in cookies');
          return false; // Token not found in cookies, user is not logged in
      }

      // Extract the token value
      const token = tokenCookie.split('=')[1];
      console.log('Token found:', token);
      
      return true; // Token is found, user is logged in
  } catch (error) {
      console.error('Error validating login status:', error);
      return false; // Token is invalid or validation failed
  }
};
