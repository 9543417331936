import { refreshToken } from './auth';  // Assuming refreshToken is implemented


const handleErrors = async (error, navigate, retryCallback = null, setErrorMessage = null) => {
  if (error.response) {
    // If error is a 401, try to refresh the token and retry the request
    if (error.response.status === 401) {
      try {
        const newAccessToken = await refreshToken();  // Assuming refreshToken is defined elsewhere
        if (newAccessToken && typeof retryCallback === 'function') {
          await retryCallback();  // Retry the request after token refresh
        } else {
          navigate('/login');  // Redirect to login if token refresh fails
        }
      } catch (refreshError) {
        if (typeof setErrorMessage === 'function') {
          setErrorMessage('Session expired. Please log in again.');
        }
        navigate('/login');  // Redirect to login if token refresh itself fails
      }
    } else {
      // Handle non-401 errors
      const message = error.response?.data?.message || 'An error occurred.';
      const stackTrace = error.response?.data?.stack_trace || '';

      if (typeof setErrorMessage === 'function') {
        // Set error message with optional stack trace
        const fullMessage = stackTrace ? `${message}\nStack Trace:\n${stackTrace}` : message;
        setErrorMessage(fullMessage);
      }

      // Log error message and stack trace to the console
      console.error('Error message:', message);
      if (stackTrace) {
        console.error('Stack trace:', stackTrace);
      }
    }
  } else {
    // If no response from the server (e.g., network issue)
    const message = 'No response received from server.';
    if (typeof setErrorMessage === 'function') {
      setErrorMessage(message);
    }
    console.error(message);
  }
};

export default handleErrors;