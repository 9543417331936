/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import Sidebar from '../utils/Sidebar.js';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const ListTriviaPolls = () => {
  const [polls, setPolls] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [openPollId, setOpenPollId] = useState(null);
  const [marketerId, setMarketerId] = useState(null);
  const [customMarketerId, setCustomMarketerId] = useState('');

  // Logout handler needs to be defined at the top level
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };


  // Fetch polls when the component mounts
  useEffect(() => {
    const fetchPolls = async () => {
      try {
        //const token = localStorage.getItem('access_token');
        const response = await api.get('/trivia_polls');
        setPolls(response.data.polls);
        setMarketerId(response.data.marketer_id);
        setErrorMessage(''); // Clear any previous error message
      } catch (error) {
        handleErrors(error, navigate, fetchPolls, setErrorMessage);
      }
    };

    fetchPolls(); // Call the fetchPolls function when the component mounts
  }, [navigate]);

  const handleOpenPoll = (pollId) => {
    setOpenPollId(pollId); // Set the poll being opened
    setCustomMarketerId(marketerId); // Prepopulate marketerId if available
  };

  const handleGoToPoll = () => {
    if (customMarketerId) {
      window.open(`/openTriviaPoll/${openPollId}/${customMarketerId}`, '_blank'); // Open the poll with marketerId as a query parameter
      setOpenPollId(null); // Reset after opening
      setCustomMarketerId(''); // Clear marketerId after use
    } else {
      setErrorMessage("Please enter a marketer ID.");
    }
  };

  const handleDeletePoll = async (pollId) => {
    try {
      await api.delete(`/delete_trivia_poll/${pollId}`); // Delete the poll by ID
      setPolls((prevPolls) => prevPolls.filter(poll => poll.poll_id !== pollId)); // Remove the deleted poll from the list
      setErrorMessage('');
    } catch (error) {
      handleErrors(error, navigate, handleDeletePoll, setErrorMessage);
    }
  };

  const handleViewResults = (pollId) => {
    navigate(`/viewTriviaPoll/${pollId}`); // Redirect to view poll results
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate('/dashboard');
  };

  // Render a list of polls with actions and error message if any
  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button onClick={handleLogout}>
                <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button onClick={handleProfile}>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>

        <h2>Your Trivia Polls</h2>

        {/* Display error message if exists */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

        {polls.length === 0 ? (
          <p style={{ color: 'black' }}>No polls available. Please create a trivia poll to get started.</p>
        ) : (
          <dl style={{ color: 'black', listStyleType: 'none', padding: 0 }}>
            {polls.map((poll) => (
              <li key={poll.poll_id} style={{ marginBottom: '20px', color: 'black' }}>
                {poll.title && <p style={{ color: 'black' }}>Title: {poll.title}</p>}
                {poll.description && <p style={{ color: 'black' }}>Description: {poll.description}</p>}
                <p style={{ color: 'black' }}>Poll ID: {poll.poll_id}</p>
                <p style={{ color: 'black' }}>Expiry: {poll.expiryHours} hours</p>
                <strong style={{ color: 'black' }}>{poll.question}</strong>
                <div>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Options:</p> {/* Options label */}
                  <ul style={{ listStyleType: 'circle', marginLeft: '20px', color: 'black' }}>
                    {poll.options.map((option, index) => (
                      <li key={`${poll.poll_id}-option-${index}`} style={{ margin: '5px 0', color: 'black' }}>
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
                {openPollId === poll.poll_id ? (
                  <div>
                    <label style={{ marginRight: '10px', color: 'black' }}>
                      Marketer ID:
                    </label>
                    <input
                      type="text"
                      value={customMarketerId}
                      onChange={(e) => setCustomMarketerId(e.target.value)}
                      placeholder="Enter Marketer ID"
                      style={{ marginRight: '10px' }}
                    />
                    <button onClick={handleGoToPoll}>Go</button>
                  </div>
                ) : (
                  <button onClick={() => handleOpenPoll(poll.poll_id)}>Open Poll</button>
                )}
                <button onClick={() => handleDeletePoll(poll.poll_id)} style={{
                  display: 'inline-block',
                  padding: '10px 20px',
                  fontSize: '16px',
                  color: '#fff',
                  backgroundColor: '#FF0000',
                  border: 'none',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  marginTop: '10px'
                }}>Delete Poll</button>
                {/* <button onClick={() => handleViewResults(poll.poll_id)}>View Poll</button> */}
              </li>
            ))}
          </dl>
        )}
        <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
      </div>
    </div>
  );
};

export default withAuthCheck(ListTriviaPolls);
