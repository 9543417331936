/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Your Axios instance
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import '../App.css'; // Import the PollTaker-specific CSS
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const Profile = () => {
  const [user, setUser] = useState({
    email: '',
    name: '',
    street: '',
    apt_suite: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    mobile_phone: '',
    work_phone: '',
    otp_required: false,
    company: '',
    job_title: '',
    title: '',
    gender: '',
    pronouns: '',
    dob: ''
  });
  const [currentPassword, setCurrentPassword] = useState('');  // Current password
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [changePassword, setChangePassword] = useState(false); // Change password checkbox
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [otpAlreadySet, setOtpAlreadySet] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  useEffect(() => {
    // Fetch user profile when the component mounts
    const fetchProfile = async () => {
      try {
        const response = await api.get('/profile');
        setUser(response.data);
        setOtpRequired(response.data.otp_required);
        setEmailVerified(response.data.email_verified);
        setErrorMessage('');
      } catch (error) {
        handleErrors(error, navigate, fetchProfile, setErrorMessage);
      }
    };

    fetchProfile();
  }, [navigate]); // Removed handleErrors from dependency array to avoid re-renders

  // Function to validate the password
  const validatePassword = (password) => {
    const passwordRulesMessage = `
1. Does not start with a numeral
2. Must have at least 1 uppercase character
3. Must have at least 1 special character from the following: ('$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#')
4. Must not contain any space characters
5. Must not contain any control or non-printable characters
6. Must not contain your email address or name in any upper or lower case combinations
`;

    const specialChars = ['$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#'];

    // Rule 1: Password must not start with a numeral
    if (/^\d/.test(password)) {
      //return 'Password must not start with a numeral';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;

    }

    // Rule 2: Must contain at least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      //return 'Password must contain at least 1 uppercase letter';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 3: Must contain at least 1 special character
    if (!specialChars.some(char => password.includes(char))) {
      //return 'Password must contain at least 1 special character';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 4: Must not contain any space characters
    if (/\s/.test(password)) {
      //return 'Password must not contain spaces';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    // Rule 5: Must not contain any control or non-printable characters
    if (/[\x00-\x1F\x7F]/.test(password)) {
      //return 'Password must not contain control or non-printable characters';
      return `Password does not meet the following requirements: ${passwordRulesMessage}`;
    }

    return null; // Password is valid
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (changePassword && newPassword !== confirmPassword) {
      setErrorMessage('New passwords do not match.');
      return;
    }

    if (!acceptedAgreement) {
      setErrorMessage('You must accept the disclosure agreement.');
      return;
    }

    // Validate password
    if (changePassword) {
      const validationError = validatePassword(newPassword);
      if (validationError) {
        setErrorMessage(validationError);
        return;
      }
    }

    try {
      const response = await api.put('/profile', {
        email: user.email,
        name: user.name,
        mobile_phone: user.mobile_phone,
        work_phone: user.work_phone,
        zip_code: user.zip_code,
        street: user.street,
        apt_suite: user.apt_suite,
        city: user.city,
        state: user.state,
        country: user.country,
        otp_required: otpRequired,
        company: user.company,
        job_title: user.job_title,
        title: user.title,
        gender: user.gender,
        pronouns: user.pronouns,
        dob: user.dob,
        current_password: currentPassword,  // Send current password to backend
        new_password: changePassword ? newPassword : null,  // Send new password only if Change Password is checked
        change_password: changePassword,    // Send flag to indicate if password should be changed
        accepted_agreement: acceptedAgreement
      });

      const sm = "Profile updated successfully.";
      const qrCodeURL = response.data.qr_code_image;  // This contains the base64-encoded image
      setQrCodeUrl(qrCodeURL);
      setOtpAlreadySet(response.data.otp_already_set);
      if (!otpAlreadySet && otpRequired && !qrCodeURL) {
        throw new Error('Error generating QR code for OTP.');
      }
      if (!response.data.otp_already_set && otpRequired) {
        alert('At this point, you must scan the QR code at the bottom of this screen with Google or Microsoft Authenticator to enable OTP.');
      }
      setErrorMessage('');
      if (response.data.otp_already_set) {
        setSuccessMessage(sm + ' OTP was already previously set. Please use your original OTP app configuration');
      }
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate('/dashboard');
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button onClick={handleLogout}>
                <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button onClick={handleProfile}>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>

        <div className="profile-section">
          <h2>Your Profile</h2>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <form onSubmit={handleProfileUpdate}>
            {/* Marketer ID */}
            <div>
              <label>
                Marketer ID:
                <input
                  type="text"
                  value={user.marketer_id}
                  readOnly
                  style={{ width: '50%', backgroundColor: '#f0f0f0', border: '1px solid #ccc' }}
                />
              </label>
            </div>
            {/* Email */}
            <div>
              <label>
                Email <span style={{ color: 'red' }}>*</span>:
                <input
                  type="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  style={{ width: '50%' }}
                  required
                />
              </label>
            </div>

            {/* Current Password (mandatory for any profile changes) */}
            {!emailVerified && (
              <div>
                <label>
                  Current Password <span style={{ color: 'red' }}>*</span>:
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </label>
              </div>
            )}

            {/* Change Password Checkbox */}
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={changePassword}
                onChange={(e) => setChangePassword(e.target.checked)}
                id="change-password"
                style={{ marginRight: '10px' }}
              />
              <label htmlFor="change-password">
                Change Password
              </label>
            </div>

            {/* New Password */}
            <div>
              <label>
                New Password:
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  disabled={!changePassword}
                  required={changePassword}
                />
              </label>
            </div>

            {/* Confirm New Password */}
            <div>
              <label>
                Confirm New Password:
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={!changePassword}
                  required={changePassword}
                />
              </label>
            </div>

            {/* Checkbox to enable/disable OTP input */}
            <div>
              <label style={{ display: 'block', marginBottom: '10px' }}>
                <input
                  type="checkbox"
                  checked={otpRequired}
                  onChange={(e) => setOtpRequired(e.target.checked)}
                />
                Enable OTP for additional security
              </label>
            </div>

            {!otpAlreadySet && otpRequired && qrCodeUrl && (
              <div>
                <h3>Scan the QR code with Google Authenticator:</h3>
                {/*<img src={qrCodeUrl} alt="QR code for Google Authenticator" />*/}
                <img
                  src={qrCodeUrl}
                  alt="QR code for Google Authenticator"
                  style={{ width: '96px', height: '96px' }} // 1 inch by 1 inch
                />
              </div>
            )}

            {/* Checkbox to accept the agreement */}
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={acceptedAgreement}
                onChange={(e) => setAcceptedAgreement(e.target.checked)}
                id="accept-agreement"
                style={{ marginRight: '10px' }}
                required
              />
              <label htmlFor="accept-agreement">
                I accept the <a href={`${apiUrl}/disclosure_agreement`} target="_blank" rel="noopener noreferrer">disclosure agreement</a>
              </label>
            </div>

            {/* Submit button */}
            <button type="submit">Update Profile</button>

            {/* Optional Section Divider */}
            <hr style={{ border: '0', height: '1px', backgroundColor: '#ddd', marginBottom: '20px' }} />

            {/* Subtitle for optional fields */}
            <h3 style={{ fontSize: '18px', marginBottom: '10px', color: '#555' }}>Additional Information (Optional)</h3>

            <div style={{ marginBottom: '15px' }}>
              <label>
                Title:
                <select
                  value={user.title}
                  onChange={(e) => setUser({ ...user, title: e.target.value })}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select...</option>
                  <option value="Mr">Mr.</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Sir">Sir</option>
                  <option value="Dr">Dr.</option>
                  <option value="Prof">Prof.</option>
                  <option value="Rev">Rev.</option>
                  <option value="Hon">Hon.</option>
                  <option value="N/A">N/A</option>
                </select>
              </label>
            </div>

            {/* Name */}
            <div>
              <label>
                Name:
                <input
                  type="text"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
              </label>
            </div>

            {/* Gender Field */}
            <div style={{ marginBottom: '15px' }}>
              <label>
                Gender:
                <select
                  value={user.gender}
                  onChange={(e) => setUser({ ...user, gender: e.target.value })}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
              </label>
            </div>

            {/* Pronoun Field */}
            <div style={{ marginBottom: '15px' }}>
              <label>
                Pronoun:
                <select
                  value={user.pronouns}
                  onChange={(e) => setUser({ ...user, pronouns: e.target.value })}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select...</option>
                  <option value="He/Him">He/Him</option>
                  <option value="She/Her">She/Her</option>
                  <option value="They/Them">They/Them</option>
                  <option value="Ze/Hir">Ze/Hir</option>
                  <option value="Ze/Zir">Ze/Zir</option>
                  <option value="Xe/Xem">Xe/Xem</option>
                  <option value="Ve/Ver">Ve/Ver</option>
                  <option value="Other">Other</option>
                  <option value="N/A">N/A</option>
                </select>
              </label>
            </div>

            <div>
              <label>
                Date of Birth (YYYY-MM-DD):
                <input
                  type="datetime-local"
                  value={user.dob}
                  onChange={(e) => setUser({ ...user, dob: e.target.value })}
                />
              </label>
            </div>

            {/* Mobile Phone */}
            <div>
              <label>
                Mobile Phone:
                <input
                  type="text"
                  value={user.mobile_phone}
                  onChange={(e) => setUser({ ...user, mobile_phone: e.target.value })}
                />
              </label>
            </div>

            {/* Work Phone */}
            <div>
              <label>
                Work Phone:
                <input
                  type="text"
                  value={user.work_phone}
                  onChange={(e) => setUser({ ...user, work_phone: e.target.value })}
                />
              </label>
            </div>

            {/* Street */}
            <div>
              <label>
                Street:
                <input
                  type="text"
                  value={user.street}
                  onChange={(e) => setUser({ ...user, street: e.target.value })}
                  style={{ width: '75%' }}
                />
              </label>
            </div>

            {/* Apt/Suite */}
            <div>
              <label>
                Apt/Suite:
                <input
                  type="text"
                  value={user.apt_suite}
                  onChange={(e) => setUser({ ...user, apt_suite: e.target.value })}
                />
              </label>
            </div>

            {/* City */}
            <div>
              <label>
                City:
                <input
                  type="text"
                  value={user.city}
                  onChange={(e) => setUser({ ...user, city: e.target.value })}
                />
              </label>
            </div>

            {/* State */}
            <div>
              <label>
                State:
                <input
                  type="text"
                  value={user.state}
                  onChange={(e) => setUser({ ...user, state: e.target.value })}
                />
              </label>
            </div>

            {/* Zip Code */}
            <div>
              <label>
                Zip Code:
                <input
                  type="text"
                  value={user.zip_code}
                  onChange={(e) => setUser({ ...user, zip_code: e.target.value })}
                />
              </label>
            </div>

            {/* Country */}
            <div>
              <label>
                Country:
                <input
                  type="text"
                  value={user.country}
                  onChange={(e) => setUser({ ...user, country: e.target.value })}
                />
              </label>
            </div>

            {/* Company */}
            <div>
              <label>
                Company:
                <input
                  type="text"
                  value={user.company}
                  onChange={(e) => setUser({ ...user, company: e.target.value })}
                />
              </label>
            </div>

            {/* Job Title */}
            <div>
              <label>
                Job Title:
                <input
                  type="text"
                  value={user.job_title}
                  onChange={(e) => setUser({ ...user, job_title: e.target.value })}
                />
              </label>
            </div>

            {/* Submit button */}
            <button type="submit">Update Profile</button>
          </form>

          {/* Cancel button */}
          <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>

        </div>

      </div>
    </div>
  );
};

export default withAuthCheck(Profile);
