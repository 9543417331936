export const triviaExamples = [
    'Where did George Washington sign the Declaration of Independence?',
    'What is the capital city of France?',
    'Who wrote the play "Hamlet"?',
    'In what year did the Titanic sink?',
    'Who was the first person to walk on the moon?',
    'Which planet is known as the Red Planet?',
    'What is the smallest country in the world?',
    'How many continents are there on Earth?',
    'Which element has the chemical symbol "O"?',
    'What is the longest river in the world?',
    'Who painted the Mona Lisa?',
    'What is the capital of Japan?',
    'In which year did World War II end?',
    'What is the largest mammal in the world?',
    'Which organ in the human body is responsible for pumping blood?',
    'What is the chemical formula for water?',
    'What is the tallest mountain in the world?',
    'Who invented the telephone?',
    'How many bones are there in the adult human body?',
    'Which country hosted the first modern Olympic Games?',
    'What is the hardest natural substance on Earth?',
    'In which year did the Berlin Wall fall?',
    'What is the national language of Brazil?',
    'Who discovered penicillin?',
    'Which planet is closest to the sun?',
    'What is the largest ocean on Earth?',
    'Who is the author of the Harry Potter series?',
    'Which bird is known for its ability to mimic sounds, including human speech?',
    'What is the most populous country in the world?',
    'How many players are there on a soccer team?',
    'What is the main ingredient in guacamole?',
    'Which gas do plants absorb from the atmosphere?',
    'What is the largest desert in the world?',
    'What currency is used in the United Kingdom?',
    'Who developed the theory of relativity?',
    'What is the process by which plants make their food?',
    'What is the official language of Egypt?',
    'Which country has the largest population in Africa?',
    'What is the largest organ in the human body?',
    'In which sport would you perform a slam dunk?',
    'Which animal is known as the "King of the Jungle"?',
    'What does the “HTTP” stand for in a website address?',
    'What is the rarest blood type?',
    'What is the hottest planet in the solar system?',
    'In what year was the first iPhone released?',
    'Who directed the movie “Titanic”?',
    'What is the primary ingredient in chocolate?',
    'How many time zones are there in Russia?',
    'What is the freezing point of water in Celsius?',
    'Which country is known as the Land of the Rising Sun?',
    'Which famous artist cut off his own ear?',
    'What is the most spoken language in the world?',
    'What is the name of the longest river in the United States?',
    'In which country is the Great Barrier Reef located?',
    'Which planet has the most moons?',
    'What is the most abundant gas in Earth’s atmosphere?',
    'How many planets are there in our solar system?',
    'What is the name of the largest rainforest in the world?',
    'Who was the first President of the United States?',
    'What does DNA stand for?',
    'Which element is essential for the production of nuclear energy?',
    'What is the capital of Canada?',
    'What is the smallest bone in the human body?',
    'Which famous ship sank after hitting an iceberg?',
    'Who is the creator of the cartoon character Mickey Mouse?',
    'Which country gave the Statue of Liberty to the United States?',
    'What is the hardest rock known to humans?',
    'Which country produces the most coffee in the world?',
    'How many hearts does an octopus have?',
    'What is the capital of South Korea?',
    'Which desert is the largest hot desert in the world?',
    'What is the official language of China?',
    'Which metal is the best conductor of electricity?',
    'What is the largest island in the world?',
    'What is the primary color of the flag of Italy?',
    'What is the rarest gemstone in the world?',
    'What is the fastest land animal?',
    'In what year did the United States land a man on the moon?',
    'What is the capital of Australia?',
    'Who invented the light bulb?',
    'Which continent is the Sahara Desert located on?',
    'What is the longest bone in the human body?',
    'Which planet is known for its rings?',
    'What is the most famous river in Egypt?',
    'Who invented the airplane?',
    'Which country is known for the Eiffel Tower?',
    'Which insect is known for its ability to produce honey?',
    'What is the highest waterfall in the world?',
    'How many chambers does the human heart have?',
    'Which bird is the largest in the world?',
    'What is the national dish of Spain?',
    'Who discovered America?',
    'What is the boiling point of water in Fahrenheit?',
    'Which is the only fruit with seeds on the outside?',
    'Which instrument has 88 keys?',
    'What is the largest species of shark?',
    'What is the second largest country by land area?',
    'What is the name of the famous clock tower in London?',
    'Which ocean is the deepest in the world?',
    'Which country is famous for its maple syrup?'
];

export const opinionExamples = [
    'Which factors are likely to affect the climate the most?',
    'Do you believe technology is improving quality of life?',
    'Should electric cars be made mandatory by 2030?',
    'Is remote work more productive than working in an office?',
    'Should governments invest more in renewable energy?',
    'Do social media platforms have a responsibility to prevent misinformation?',
    'Is space exploration worth the investment?',
    'Should companies implement a four-day workweek?',
    'Do you believe standardized testing accurately measures student ability?',
    'Should healthcare be free for everyone?',
    'Is artificial intelligence a threat to jobs in the future?',
    'Do you think climate change is the biggest challenge humanity faces?',
    'Should there be stricter laws on gun ownership?',
    'Do you support universal basic income?',
    'Should governments ban single-use plastics?',
    'Is the gig economy good for workers?',
    'Should public transportation be free?',
    'Is online education as effective as traditional classroom learning?',
    'Should social media influencers be held to the same advertising standards as traditional media?',
    'Do you think celebrities should have a role in politics?',
    'Is fast food responsible for the rise in obesity rates?',
    'Should countries enforce mandatory military service?',
    'Do you believe that cryptocurrencies will replace traditional currencies?',
    'Is virtual reality the future of entertainment?',
    'Should college education be free for all students?',
    'Do you think self-driving cars are safe?',
    'Is privacy more important than national security?',
    'Should employers offer flexible working hours?',
    'Do you believe the legal drinking age should be lowered?',
    'Should animal testing for cosmetics be banned?',
    'Is it ethical to use animals for scientific research?',
    'Should school uniforms be mandatory?',
    'Do you think reality TV is harmful to society?',
    'Is it more important to save money or enjoy life?',
    'Do you believe humans are responsible for the extinction of species?',
    'Should cities invest more in bike lanes?',
    'Do you think zoos should exist?',
    'Should the voting age be lowered to 16?',
    'Do you believe in the existence of extraterrestrial life?',
    'Should social media companies be regulated by governments?',
    'Is it ethical to genetically modify organisms for food production?',
    'Do you think renewable energy sources can fully replace fossil fuels?',
    'Should there be more restrictions on who can own a pet?',
    'Is homeschooling better than traditional schooling?',
    'Do you support the legalization of marijuana?',
    'Should the minimum wage be increased?',
    'Is technology advancing too quickly for society to keep up?',
    'Do you think space tourism will become mainstream in the next 50 years?',
    'Should voting be mandatory in all elections?',
    'Is it better to be a specialist or a generalist in your career?',
    'Do you think news outlets are biased?',
    'Should artificial intelligence be used in healthcare to diagnose diseases?',
    'Is social media addiction a real issue?',
    'Do you think the Olympics should include eSports?',
    'Should countries prioritize their own citizens over global issues?',
    'Is it better to rent a home or buy one?',
    'Do you believe renewable energy is the solution to global warming?',
    'Should parents be responsible for their children’s social media usage?',
    'Is it important to teach financial literacy in schools?',
    'Should high schools offer more vocational training?',
    'Do you believe companies should prioritize profits or social responsibility?',
    'Is it better to travel the world or focus on building a career?',
    'Do you think space exploration should be a global effort or led by individual countries?',
    'Should public transportation be funded entirely by the government?',
    'Do you think climate change can be reversed?',
    'Should students have more input in the way schools are run?',
    'Is the influence of big tech companies too strong?',
    'Should voting rights be extended to non-citizens?',
    'Do you believe the wealth gap is a problem in society?',
    'Is it important for children to learn coding in school?',
    'Should internet access be considered a basic human right?',
    'Do you think virtual currencies like Bitcoin will become mainstream?',
    'Is it better to save for retirement early or enjoy life while you’re young?',
    'Should there be restrictions on the use of facial recognition technology?',
    'Is the commercialization of space a positive or negative development?',
    'Should the use of plastic bags be banned completely?',
    'Do you think genetically modified foods are safe to eat?',
    'Should companies be required to offer paid parental leave?',
    'Is influencer marketing an effective form of advertising?',
    'Should school hours be shortened to improve student well-being?',
    'Do you believe that voting should be done online in the future?',
    'Is climate change an immediate threat or a long-term challenge?',
    'Should tech companies be broken up to prevent monopolies?',
    'Is globalization more beneficial or harmful to local economies?',
    'Do you think social media does more harm than good?',
    'Is there enough regulation in the tech industry?',
    'Do you believe smart cities will improve quality of life?',
    'Should the government fund more public art projects?',
    'Is streaming replacing traditional forms of media consumption?',
    'Should internet data be treated as a public utility?',
    'Do you think children should be allowed to use smartphones at a young age?',
    'Should we focus on colonizing Mars or fixing Earth’s problems?',
    'Is fast fashion harmful to the environment?',
    'Should governments impose higher taxes on junk food?',
    'Do you believe that remote learning is here to stay?',
    'Should free healthcare be a fundamental human right?',
    'Is the wealth gap getting wider or closing?',
    'Should tech companies have the right to censor users?',
    'Do you believe that universal basic income is a good idea?',
    'Should countries focus on fixing poverty before investing in space exploration?',
];

export const researchExamples = [
    'Pick your favorite coffee bean variety?',
    'Which brand of smartphones do you use?',
    'What features do you value the most in a new car?',
    'Which streaming service do you use the most?',
    'What factors influence your decision to purchase a product?',
    'How often do you buy organic products?',
    'What is your preferred method of online payment?',
    'Which social media platform do you use the most?',
    'How much do you typically spend on clothes each month?',
    'Which grocery store do you visit most often?',
    'What brand of laptop do you prefer?',
    'How frequently do you upgrade your smartphone?',
    'What is the most important factor when choosing a vacation destination?',
    'Which airline do you prefer for international travel?',
    'Which type of fitness equipment do you own?',
    'What time of day do you typically shop online?',
    'How important is brand loyalty in your purchasing decisions?',
    'Which video game console do you use the most?',
    'What kind of transportation do you use for your daily commute?',
    'Which type of pet food do you buy for your pets?',
    'How often do you order takeout food?',
    'Which brand of toothpaste do you use?',
    'What features do you look for when buying a new television?',
    'What influences your decision to choose a particular restaurant?',
    'How often do you use ride-sharing services?',
    'Which bank do you use for your personal finances?',
    'What type of camera do you use for photography?',
    'Which type of skincare products do you purchase?',
    'How often do you participate in loyalty programs?',
    'Which fitness brand do you prefer for workout clothes?',
    'Which type of beverages do you drink the most?',
    'How often do you attend live events such as concerts or sports games?',
    'Which meal delivery service do you use the most?',
    'Which credit card brand do you prefer for daily purchases?',
    'What is your favorite brand of running shoes?',
    'How often do you go to the movies?',
    'Which type of streaming device do you use to watch TV?',
    'What type of insurance do you prioritize when purchasing coverage?',
    'Which brand of appliances do you trust the most?',
    'Which coffee shop chain do you visit most often?',
    'What brand of headphones do you prefer?',
    'How often do you go on international vacations?',
    'Which type of health supplements do you use regularly?',
    'What influences your choice of fitness tracker or smartwatch?',
    'How often do you buy luxury goods?',
    'Which grocery delivery service do you prefer?',
    'Which type of home decor do you like the most?',
    'Which type of e-book reader do you use?',
    'How often do you shop at local businesses versus big chains?',
    'Which type of cleaning products do you use at home?',
    'Which brand of sunglasses do you prefer?',
    'Which brand of bottled water do you buy?',
    'How often do you upgrade your home appliances?',
    'Which fitness app do you use the most?',
    'Which ride-hailing app do you use the most?',
    'Which food delivery app do you prefer?',
    'How often do you buy products based on online reviews?',
    'Which grocery store loyalty program do you participate in?',
    'How often do you purchase new tech gadgets?',
    'Which type of travel accommodation do you prefer (hotels, Airbnb, etc.)?',
    'What is your favorite brand of athletic wear?',
    'Which magazine subscription do you currently have?',
    'What is the most important factor in choosing a new laptop?',
    'Which type of pet accessories do you buy the most?',
    'Which wireless carrier do you use for mobile service?',
    'How often do you purchase eco-friendly products?',
    'Which brand of deodorant do you prefer?',
    'Which vacation booking platform do you use the most?',
    'How frequently do you buy home improvement products?',
    'What type of fitness classes do you attend the most?',
    'Which e-commerce platform do you shop from the most?',
    'Which airline rewards program do you participate in?',
    'Which brand of outdoor gear do you prefer for camping or hiking?',
    'How often do you shop at farmers markets?',
    'Which brand of computer accessories do you buy?',
    'Which video conferencing platform do you use the most?',
    'Which type of online education platform do you prefer?',
    'How often do you buy new furniture?',
    'Which brand of wearable tech do you use?',
    'Which pet grooming service do you use the most?',
    'How often do you upgrade your gaming console?',
    'What type of fitness equipment do you regularly use?',
    'Which brand of vitamins or supplements do you prefer?',
    'How often do you purchase new bedding or home textiles?',
    'Which type of cookware do you use the most?',
    'What type of personal care products do you buy the most?',
    'Which travel rewards program do you use the most?',
    'How often do you dine out compared to cooking at home?',
    'Which brand of speakers or sound systems do you use?',
    'How often do you buy new outdoor furniture?',
    'Which type of office supplies do you purchase the most?',
    'Which streaming music service do you subscribe to?',
    'Which brand of energy drink do you consume the most?',
    'How often do you shop for books or e-books?',
    'What is your preferred method of booking a vacation?',
    'Which brand of power tools do you use for home improvement projects?',
    'How often do you purchase new skincare products?',
    'Which makeup brand do you buy the most?',
    'What type of bedding materials do you prefer?',
    'How often do you purchase electronics accessories?',
];