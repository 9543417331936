/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

// Logout.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import handleErrors from '../auth/handleErrors';
import api from '../api'; // Assuming you have Axios configured here

const ConfirmTriviaPoll = () => {
  const { poll_id } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isConfirming, setIsConfirming] = useState('');

  useEffect(() => {
    const handleConfirm = async () => {
      try {
        await api.get(`/confirm_trivia_poll/${poll_id}`); // Call your backend logout endpoint
        setErrorMessage(null); // Clear the error message
        setIsConfirming(true); // Set isConfirming to false
        navigate('/triviaPolls');
      } catch (error) {
        handleErrors(error, navigate, null, setErrorMessage);
      }
    };

    handleConfirm();
  }, [navigate, poll_id]);

  return (
    <div>
      {isConfirming && !errorMessage ? (
        <p>Confirming...</p>
      ) : (
        <p style={{ color: 'black' }}>{errorMessage}</p>
      )}
    </div>
  );
};

export default ConfirmTriviaPoll;
