/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
