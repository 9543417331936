/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is configured
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const CreateResearchGroup = () => {
  const [groupName, setGroupName] = useState(''); // State for research group name
  const [groupTitle, setGroupTitle] = useState('');
  const [groupDesc, setGroupDesc] = useState('');
  const [groupId, setGroupId] = useState(null); // ID returned after research group creation
  const [errorMessage, setErrorMessage] = useState('');
  const [realTime, setRealTime] = useState(false);  // Capture real-time checkbox
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  // Function to create a new research group
  const handleCreateResearchGroup = async (e) => {
    if (e) e.preventDefault();

    try {
      const response = await api.post('/create_research_group', {
        group_name: groupName,
        group_title: groupTitle,
        group_desc: groupDesc,
        real_time: realTime  // Include realTime in the API request payload
      });

      setGroupId(response.data.group_id);
      setErrorMessage('');
      navigate('/researchGroups'); // Navigate to research group list after creation
    } catch (error) {
      handleErrors(error, navigate, handleCreateResearchGroup, setErrorMessage);
    }
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate('/dashboard');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Research Group Dashboard</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Create a Research Group</h2>
        <form onSubmit={handleCreateResearchGroup}>
          <div>
            <label>
              Group Name:
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Group Title:
              <input
                type="text"
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Group Description:
              <input
                type="text"
                value={groupDesc}
                onChange={(e) => setGroupDesc(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>
          <label>
            Real-Time Poll:
            <input
              type="checkbox"
              checked={realTime}
              onChange={(e) => setRealTime(e.target.checked)}
            />
          </label><p />

          <button type="submit">Create Research Group</button>
          <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
        </form>

        {groupId && <p>Research group created successfully! Group ID: {groupId}</p>}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default withAuthCheck(CreateResearchGroup);
