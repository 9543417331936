/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';  // Assuming Axios instance is imported
import withAuthCheck from '../hoc/withAuthCheck';
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import { getFutureDateString } from '../utils/utils';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const CreateResearchPoll = () => {
  const [question, setQuestion] = useState('');  // Capture the poll question
  const [options, setOptions] = useState(['', '']);  // Start with two empty options
  const [expiry, setExpiry] = useState('');  // Capture the expiry date (full date expected)
  const [pollId, setPollId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Function to add more options
  const addOption = () => {
    setOptions([...options, '']);
  };

  // Function to delete an option
  const deleteOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout');  // Call backend logout endpoint
      localStorage.removeItem('access_token');  // Clear the access token from localStorage
      navigate('/login');  // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  useEffect(() => {
    const defaultExpiry = getFutureDateString(720); // Now + 30 days
    setExpiry(defaultExpiry);
  }, []);

  // Function to handle poll creation
  const handleCreateResearchPoll = async (e) => {
    if (e) e.preventDefault();

    try {
      const response = await api.post('/create_research_poll', {
        question,
        options,
        expiry  // Full date is expected (e.g. 2024-12-31T23:59:59)
      });

      setPollId(response.data.poll_id);  // Capture poll ID after creation
      setErrorMessage('');
      navigate('/researchPolls');  // Redirect to Research Polls page after creation
    } catch (error) {
      const stackTrace = error.response.data.stack_trace || '';
      console.log("Stack Trace:", stackTrace);
      handleErrors(error, navigate, handleCreateResearchPoll, setErrorMessage);
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Research Poll Creation</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Create a Research Poll</h2>
        <form onSubmit={handleCreateResearchPoll}>
          <div>
            <label>
              Question:
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>

          {options.map((option, index) => (
            <div key={index}>
              <label>
                Option {index + 1}:
                <input
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                  required
                />
                <button type="button" onClick={() => deleteOption(index)}>Remove</button>
              </label>
            </div>
          ))}

          <button type="button" onClick={addOption}>Add Option</button>

          <div>
            <label>
              Expiry (YYYY-MM-DD HH:MM:SS):
              <input
                type="datetime-local"
                value={expiry}
                onChange={(e) => setExpiry(e.target.value)}
                required
              />
            </label>
          </div>

          <button type="submit">Create Research Poll</button>
        </form>

        {pollId && <p>Research Poll created successfully! Poll ID: {pollId}</p>}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default withAuthCheck(CreateResearchPoll);
