/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { refreshToken } from '../auth/auth.js'; // Assuming refreshToken is implemented to handle token refresh
import withAuthCheck from '../hoc/withAuthCheck';
import Sidebar from '../utils/Sidebar.js'; // Adjust the path based on your file structure
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const DemoSidebar = () => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['', '']); // Start with two options
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [expiryHours, setExpiryHours] = useState(4); // State for expiry hours
    const [pollId, setPollId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Function to add more options
    const addOption = () => {
        setOptions([...options, '']);
    };

    // Logout handler needs to be defined at the top level
    const handleLogout = async () => {
        try {
            await api.post('/logout'); // Call your backend logout endpoint
            localStorage.removeItem('access_token'); // Clear the access token from localStorage
            navigate('/login'); // Redirect to login after logout
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleProfile = () => {
        // Navigate back to the profile
        navigate('/profile');
    };

    // Function to delete an option
    const deleteOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    // Function to create a new poll
    const handleCreatePoll = async (e) => {
        if (e) e.preventDefault();

        try {
            const response = await api.post('/create_trivia_poll', {
                question,
                options,
                correct_answer: correctAnswer,
                expiryHours: parseInt(expiryHours, 10) // Send expiryHours as an integer
            });

            setPollId(response.data.poll_id);
            setErrorMessage('');
            navigate('/triviaPolls'); // Navigate to list after poll creation
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleErrors = async (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                try {
                    const newAccessToken = await refreshToken();
                    if (newAccessToken) {
                        handleCreatePoll(); // Retry creating poll after refreshing token
                    } else {
                        setErrorMessage('Failed to refresh token. Please log in again.');
                        navigate('/login');
                    }
                } catch (refreshError) {
                    setErrorMessage('Session expired. Please log in again.');
                    navigate('/login');
                }
            } else {
                setErrorMessage(error.response.data.message || 'Failed to create poll.');
            }
        } else {
            setErrorMessage('No response received from server.');
        }
    };

    const handleCancel = () => {
        // Navigate back to the dashboard
        navigate('/dashboard');
    };

    return (
        <div className="container">
            {/* Sidebar Menu */}
            <Sidebar />
            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net - Because Everythings A Poll</div>
                    <div className="auth-buttons">
                        <button onClick={handleLogout}>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button onClick={handleProfile}>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>

                <h2>Create a Poll</h2>
                <form onSubmit={handleCreatePoll}>
                    <div>
                        <label>
                            Question:
                            <input
                                type="text"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                style={{ width: '75%' }}
                                required
                            />
                        </label>
                    </div>

                    {options.map((option, index) => (
                        <div key={index}>
                            <label>
                                Option {index + 1}:
                                <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => {
                                        const newOptions = [...options];
                                        newOptions[index] = e.target.value;
                                        setOptions(newOptions);
                                    }}
                                    required
                                />
                                <button type="button" onClick={() => deleteOption(index)}>Remove</button>
                            </label>
                        </div>
                    ))}

                    <button type="button" onClick={addOption}>Add Option</button>

                    <div>
                        <label>
                            Correct Answer:
                            <select
                                value={correctAnswer}
                                onChange={(e) => setCorrectAnswer(e.target.value)}
                                required
                            >
                                <option value="">Select correct answer</option>
                                {options.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <div>
                        <label>
                            Expiry (in hours):
                            <input
                                type="number"
                                value={expiryHours}
                                onChange={(e) => setExpiryHours(e.target.value)}
                                required
                                placeholder="Enter expiry time in hours"
                            />
                        </label>
                    </div>

                    <button type="submit">Create Poll</button>
                    <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
                </form>

                {pollId && <p>Poll created successfully! Poll ID: {pollId}</p>}
                {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
            </div>
        </div>
    );
};

export default withAuthCheck(DemoSidebar);