/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import api from '../api'; // Assuming you have set up Axios instance
import { useNavigate } from 'react-router-dom'; // Assuming you use this to navigate after login
import handleErrors from '../auth/handleErrors'; // Assuming you have this function to handle errors
import '../App.css';
import logoutIcon from '../logout_icon.png';
import Sidebar from '../utils/Sidebar.js';
import profileIcon from '../profile_icon.png';
import slogan from '../aipolls_slogan.gif';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(''); // Add OTP state
  const [otpRequired, setOtpRequired] = useState(false); // Add OTP set state
  const [errorMessage, setErrorMessage] = useState('');
  const [, setIsAuthenticated] = useState(false);
  const [signupMode, setSignupMode] = useState(false); // Add signup mode state
  const navigate = useNavigate(); // To redirect after login

  useEffect(() => {
    if (signupMode) {
      console.log('Redirecting to signup page');
      navigate('/signup'); // Redirect to signup page when signupMode is true
    }
  }, [signupMode, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password, otp, otpRequired });
      // Handle successful login
      setIsAuthenticated(true);
      localStorage.setItem('access_token', response.data.access_token);
      console.log("Login successful");
      setErrorMessage('');
      navigate('/dashboard');
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Analytics Dashboard</div>
          <div className="auth-buttons">
            {(
              <button>
                <img alt="Login" title="Login" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>

        <h2>Login</h2>
        {errorMessage && <h3 style={{ color: 'red' }}>{errorMessage}</h3>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ display: 'block', marginBottom: '10px' }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ display: 'block', marginBottom: '10px' }}
          />

          {/* OTP input, shown only if OTP is required */}
          <input
            type="text"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={!otpRequired} // Disable if otpSet is false
            required={otpRequired}  // Required only if otpSet is true
            style={{
              display: 'block',
              marginBottom: '10px',
              backgroundColor: otpRequired ? 'white' : '#e0e0e0', // Change background based on enabled/disabled
            }}
          />

          {/* Checkbox to enable/disable OTP input */}
          <label style={{ display: 'block', marginBottom: '10px' }}>
            <input
              type="checkbox"
              checked={otpRequired}
              onChange={(e) => setOtpRequired(e.target.checked)}
            />
            I have set an OTP
          </label>

          <button type="submit" style={{ display: 'block', marginBottom: '10px' }}>
            Login
          </button>
        </form>
        <Link to="/forgotPassword" style={{ display: 'block', marginBottom: '10px' }}>
          Set or Reset Password?
        </Link>
        <button onClick={() => setSignupMode(true)} style={{ display: 'block' }}>
          Need an account? Signup
        </button>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <img
            src={slogan}
            alt="Signup Slogan"
            style={{ width: '300px', height: 'auto' }}
          />
        </div>

      </div>
    </div>
  );
};

export default Login;
