/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is configured
import { refreshToken } from '../auth/auth.js'; // Assuming refreshToken is implemented to handle token refresh
import withAuthCheck from '../hoc/withAuthCheck';
import Sidebar from '../utils/Sidebar.js';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const CreateTriviaGroup = () => {
  const [groupName, setGroupName] = useState(''); // State for trivia group name
  const [groupTitle, setGroupTitle] = useState('');
  const [groupDesc, setGroupDesc] = useState('');
  const [groupId, setGroupId] = useState(null); // ID returned after group creation
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };

  // Function to create a new trivia group
  const handleCreateTriviaGroup = async (e) => {
    if (e) e.preventDefault();

    try {
      const response = await api.post('/create_trivia_group', {
        group_name: groupName,
        group_title: groupTitle,
        group_desc: groupDesc
      });

      setGroupId(response.data.group_id);
      setErrorMessage('');
      navigate('/listTriviaGroups'); // Navigate to trivia group list after creation
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            handleCreateTriviaGroup(); // Retry creating trivia group after refreshing token
          } else {
            setErrorMessage('Failed to refresh token. Please log in again.');
            navigate('/login');
          }
        } catch (refreshError) {
          setErrorMessage('Session expired. Please log in again.');
          navigate('/login');
        }
      } else {
        setErrorMessage(error.response.data.message || 'Failed to create trivia group.');
      }
    } else {
      setErrorMessage('No response received from server.');
    }
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate('/dashboard');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Create a Trivia Group</h2>
        <form onSubmit={handleCreateTriviaGroup}>
          <div>
            <label>
              Group Name:
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Group Title:
              <input
                type="text"
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Group Description:
              <input
                type="text"
                value={groupDesc}
                onChange={(e) => setGroupDesc(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </label>
          </div>
          <p>These are always real-time!</p>
          <button type="submit">Create Trivia Group</button>
          <button onClick={handleCancel} style={{ marginTop: '20px' }}>Cancel</button>
        </form>

        {groupId && <p>Trivia group created successfully! Group ID: {groupId}</p>}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default withAuthCheck(CreateTriviaGroup);
