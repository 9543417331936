/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import withAuthCheck from '../hoc/withAuthCheck'; // Assuming withAuthCheck HOC is implemented
import handleErrors from '../auth/handleErrors';

const DeleteTriviaPoll = () => {
  const { poll_id } = useParams(); // Get poll_id from URL
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const handleDeletePoll = async () => {
      try {
        const response = await api.delete(`/delete_trivia_poll/${poll_id}`);
        setSuccessMessage(response.data.message); // Success message from server
        setErrorMessage('');
        setTimeout(() => {
          navigate('/dashboard'); // Redirect back to dashboard after deletion
        }, 2000); // Wait 2 seconds before redirecting
      } catch (error) {
        handleErrors(error, navigate, handleDeletePoll, setErrorMessage);
      }
    };

    handleDeletePoll();
  }, [poll_id, navigate]);

  return (
    <div>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {!errorMessage && !successMessage && <p>Deleting poll...</p>}
    </div>
  );
};

export default withAuthCheck(DeleteTriviaPoll);
