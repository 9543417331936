import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import { getRandomColor } from '../utils/utils'
import Sidebar from '../utils/Sidebar.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Bar } from 'react-chartjs-2';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ViewSingleTriviaResults = () => {
    const [pollAuthor, setPollAuthor] = useState(''); // Store the poll author
    const [totalVotes, setTotalVotes] = useState(0); // Store the total number of votes
    const [question, setQuestion] = useState(''); // Store the poll question
    const [chartData, setChartData] = useState(null);
    const [campaignStartDate, setCampaginStartDate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { pollId, triviaSessionId } = useParams(); // Get the session UUID from the URL

    const navigate = useNavigate();
    const colorsRef = useRef([]);

    useEffect(() => {
        const fetchOptions = async () => {
            const response = await api.get(`/get_single_trivia_results/${pollId}/${triviaSessionId}`);
            const { options } = response.data;
            colorsRef.current = options.map(() => getRandomColor());
        };
        fetchOptions();
    }, [pollId, triviaSessionId]);

    const fetchPollResults = useCallback(async () => {
        try {
            // Fetch poll options and results for the session
            const response = await api.get(`/get_single_trivia_results/${pollId}/${triviaSessionId}`); // Adjust to match your route
            const { options, voteDistribution } = response.data; // Assuming you return options and vote counts
            setTotalVotes(response.data.totalVotes);
            setQuestion(response.data.question);
            setCampaginStartDate(response.data.start_date);
            setPollAuthor(response.data.name);

            // Prepare dynamic colors for the chart
            //const backgroundColors = options.map(() => getRandomColor());

            // Prepare chart data
            setChartData({
                labels: options,
                datasets: [
                    {
                        label: 'Number of Votes',
                        data: voteDistribution,
                        backgroundColor: colorsRef.current,
                    },
                ],
            });
        } catch (error) {
            await handleErrors(error, navigate, fetchPollResults, setErrorMessage);
        }
    }, [pollId, triviaSessionId, navigate]);

    // Function to generate and download the PDF report
    const handleDownloadReport = async () => {
        // Generate the chart as an image
        const chartCanvas = document.getElementById('poll-results-chart');
        const chartImage = await html2canvas(chartCanvas).then((canvas) => canvas.toDataURL('image/png'));

        // Create a PDF document
        const doc = new jsPDF();

        // Set metadata for the PDF
        doc.setProperties({
            title: 'AIPolls.Net Opinion Poll Chart',
            subject: `${question}`,
            author: `${pollAuthor}`,
            keywords: 'poll, results, AIPolls.Net',
            creator: 'AIPolls.Net'
        });
        // Add poll question and vote data to the PDF
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text(`Poll Question: ${question}`, 10, 10);
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');
        doc.text(`Poll ID: ${pollId}`, 10, 15);
        doc.setFont('helvetica', 'normal');
        doc.text(`Opinion Campaign ID: ${triviaSessionId}`, 10, 20);
        doc.setFont('helvetica', 'normal');
        doc.text(`Campaign Start Date: ${campaignStartDate}`, 10, 25);
        doc.text(`Campaign Strength: ${totalVotes} participants`, 10, 30);
        doc.setFont('helvetica', 'bold');
        doc.text(`Report Author: ${pollAuthor}`, 10, 35);
        doc.setFont('helvetica', 'italic');
        doc.text(`Report Generated: ${new Date().toLocaleString()}`, 10, 40);

        // Add the chart image to the PDF
        doc.addImage(chartImage, 'PNG', 10, 40, 180, 100); // Position and size the chart

        // Save the PDF
        doc.save(`poll_results_${pollId}.pdf`);
    };

    const handleLogout = async () => {
        try {
            await api.post('/logout'); // Call your backend logout endpoint
            localStorage.removeItem('access_token'); // Clear the access token from localStorage
            navigate('/login'); // Redirect to login after logout
        } catch (error) {
            handleErrors(error, navigate, null, setErrorMessage);
        }
    };

    const handleProfile = () => {
        navigate('/profile');
    };

    // Fetch poll results every second
    useEffect(() => {
        const intervalId = setInterval(fetchPollResults, 1000); // Call fetchPollResults every 1000 ms (1 second)

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [fetchPollResults]);

    return (
        <div className="container">
            {/* Sidebar Menu */}
            <Sidebar />
            {/* Main content */}
            <div className="main-content">
                <div className="header">
                    <div className="logo">AIPolls.Net Trivia Poll Results</div>
                    <div className="auth-buttons">
                        <button onClick={handleLogout}>
                            <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                        <button onClick={handleProfile}>
                            <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </div>

                <h2>Poll Results</h2>

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <h3>{question}</h3>
                <p>Campaign ID: {triviaSessionId}</p>
                <p>Poll ID: {pollId}</p>
                <p>Campaign Start Date: {campaignStartDate}</p>
                <p>Report Author: {pollAuthor}</p>
                <p>Question: {question}</p>
                <p>Trivia Participants: {totalVotes}</p>

                {chartData ? (
                    <div>
                        <Bar
                            id="poll-results-chart"
                            data={chartData}
                            options={{
                                responsive: true,
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Options',
                                        },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Number of Votes',
                                        },
                                        ticks: {
                                            precision: 0, // Ensure integer values
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                ) : (
                    <p>Loading poll results...</p>
                )}
                <button onClick={handleDownloadReport} style={{ marginTop: '20px' }}>
                    Download Report
                </button>
            </div>
        </div>
    );
};

export default withAuthCheck(ViewSingleTriviaResults);
