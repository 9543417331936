/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import withAuthCheck from '../hoc/withAuthCheck'; // Assuming withAuthCheck HOC is implemented
import handleErrors from '../auth/handleErrors';

// Poll Author route for opening a trivia poll
const OpenTriviaPoll = () => {
  const { pollId } = useParams();
  const { marketerId } = useParams();
  const [qrCodeUrl, setQrCodeUrl] = useState('');  // Store QR code URL after opening the poll
  const [pollUrl, setPollUrl] = useState('');  // Store poll URL after opening the poll
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [ sessionId, setSessionId ] = useState('');

  useEffect(() => {
    // Fetch the specific poll
    const openPoll = async () => {
      try {
        console.log("OPEN POLL: poll_id = " + pollId);
        const response = await api.get(`/open_trivia_poll/${pollId}/${marketerId}`);
        const qrCodeURL = response.data.qr_code_image;  // This contains the base64-encoded image
        setQrCodeUrl(qrCodeURL);  // Set the state with the base64-encoded QR code
        setSessionId(response.data.session_id); // Set the poll session ID
        setPollUrl(response.data.poll_url);  // Set the poll URL
        setErrorMessage('');
      } catch (error) {
        handleErrors(error, navigate, openPoll, setErrorMessage);
      }
    };

    openPoll();
  }, [pollId, marketerId, navigate]); // Run the effect whenever the poll_id changes

  return (
    <div>
      {errorMessage ? (
        // Display the error message if there is one
        <div style={{ color: 'red' }}>
          <h2>Error:</h2>
          <p>{errorMessage}</p>
        </div>
      ) : (
        // Display the QR code and success message if no error
        <>
          <h2>Poll Opened!</h2>
          <p>Share the poll with participants using the generated QR code to vote.</p>
          <p>Session ID: {sessionId}</p>
          <p>Poll ID: {pollId}</p>
          <p>Marketer ID: {marketerId}</p>
          <p>Poll URL: {pollUrl}</p>
          <p/>
          {qrCodeUrl && (
            <div>
              <h3>Scan the QR code to vote</h3>
              <img src={qrCodeUrl} alt="QR code for Google or MS Authenticator" style={{ width: '96px', height: '96px' }} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withAuthCheck(OpenTriviaPoll);