/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import withAuthCheck from '../hoc/withAuthCheck'; // Assuming withAuthCheck HOC is implemented
import handleErrors from '../auth/handleErrors';
import Sidebar from '../utils/Sidebar.js';
import api from '../api'; // Assuming Axios instance is configured
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';

const ManageOpinionGroupPoll = () => {
  const [groups, setGroups] = useState([]); // Opinion groups
  const [polls, setPolls] = useState([]); // All opinion polls
  const [selectedGroup, setSelectedGroup] = useState(''); // Selected opinion group
  const [selectedPolls, setSelectedPolls] = useState([]); // Polls selected in the group
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate('/profile');
  };


  // Fetch all opinion groups
  const fetchGroups = useCallback(async () => {
    try {
      const response = await api.get('/opinion_groups');
      setGroups(response.data.groups);
      if (response.data.length === 0) {
        setErrorMessage('You must first create an opinion group.');
      }
    } catch (error) {
      await handleErrors(error, navigate, fetchGroups, setErrorMessage); // Handle errors using the handleErrors function
    }
  }, [navigate]);

  // Fetch all opinion polls
  const fetchPolls = useCallback(async () => {
    try {
      const response = await api.get('/opinion_polls');
      setPolls(response.data.polls);
      if (response.data.length === 0) {
        setErrorMessage('You must first create an opinion poll.');
      }
    } catch (error) {
      await handleErrors(error, navigate, fetchPolls, setErrorMessage);

    }
  }, [navigate]);

  // Fetch polls associated with the selected group
  const fetchPollsForGroup = async (groupUuid) => {
    try {
      const response = await api.get(`/opinion_polls_for_group/${groupUuid}`);
      setSelectedPolls(response.data.map(poll => poll.poll_id));
    } catch (error) {
      await handleErrors(error, navigate, fetchPollsForGroup, setErrorMessage); // Handle errors using the handleErrors function
    }
  };

  // Handle group selection change
  const handleGroupChange = (e) => {
    const groupUuid = e.target.value;
    setSelectedGroup(groupUuid);
    fetchPollsForGroup(groupUuid); // Fetch polls when group changes
  };

  // Handle poll selection (add/remove from selected polls)
  const handlePollChange = (pollId) => {
    setSelectedPolls(prevSelected =>
      prevSelected.includes(pollId)
        ? prevSelected.filter(id => id !== pollId) // Unselect if already selected
        : [...prevSelected, pollId] // Select if not already selected
    );
  };

  // Handle Save button
  const handleSave = async () => {
    try {
      await api.post(`/save_opinion_polls_for_group/${selectedGroup}`, {
        poll_ids: selectedPolls,
      });
      navigate('/dashboard'); // Navigate to dashboard after saving
    } catch (error) {
      await handleErrors(error, navigate, handleSave, setErrorMessage); // Use the handleErrors function for error handling
    }
  };

  // Handle Cancel button
  const handleCancel = () => {
    navigate('/dashboard'); // Navigate to dashboard without saving
  };

  // Check login status and fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchGroups();
        fetchPolls();
      } catch (error) {
        await handleErrors(error, navigate, null, setErrorMessage); // Handle errors using the handleErrors function

      }
    };
    fetchData();
  }, [navigate, fetchGroups, fetchPolls]);

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Opinion Group Dashboard</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Assign Opinion Polls to Group</h2>

        {/* Display error message in the page */}
        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

        {groups.length === 0 || polls.length === 0 ? (
          <p>No opinion groups or polls available.</p> // Remove errorMessage here
        ) : (
          <form>
            <div>
              <label>
                Select Opinion Group:
                <select value={selectedGroup} onChange={handleGroupChange} style={{ overflowY: 'scroll', maxHeight: '100px' }}>
                  <option value="">Select a group</option>
                  {groups.map((group) => (
                    <option key={group.group_uuid} value={group.group_uuid}>
                      {group.group_name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div style={{ maxHeight: '200px', overflowY: 'scroll', marginTop: '20px' }}>
              <label>Select Opinion Polls:</label>
              {polls.map((poll) => (
                <div key={poll.poll_id}>
                  <input
                    type="checkbox"
                    id={`poll_${poll.poll_id}`}
                    value={poll.poll_id}
                    checked={selectedPolls.includes(poll.poll_id)}
                    onChange={() => handlePollChange(poll.poll_id)}
                  />
                  <label htmlFor={`poll_${poll.poll_id}`}>{poll.question}</label>
                </div>
              ))}
            </div>

            <div style={{ marginTop: '20px' }}>
              <button type="button" onClick={handleSave}>Save</button>
              <button type="button" onClick={handleCancel} style={{ marginLeft: '10px' }}>Cancel</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default withAuthCheck(ManageOpinionGroupPoll);
