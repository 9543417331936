/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import api from '../api'; // Assuming you have set up an Axios instance
import '../App.css';
import Sidebar from '../utils/Sidebar.js';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user_hash } = useParams();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/reset_password', { user_hash: user_hash, password });
      setSuccessMessage(response.data.message || 'Password successfully reset.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to reset password. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword} style={{ maxWidth: '400px', margin: '0 auto' }}>
          {/* Password Field */}
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
              Password:
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
              required
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                color: '#333',
              }}
            />
          </div>

          {/* Hidden Field for user_hash */}
          <input type="hidden" name="user_hash" value={user_hash} />

          {/* Reset Password Button */}
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Reset Password
          </button>
        </form>

        {/* Success or Error Message */}
        {successMessage && <p style={{ color: 'green', marginTop: '15px' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red', marginTop: '15px' }}>{errorMessage}</p>}

        {/* Redirect to Login Button */}
        {successMessage && (
          <button
            onClick={() => navigate('/login')}
            style={{
              marginTop: '20px',
              padding: '10px',
              backgroundColor: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Back to Login
          </button>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;