/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api'; // Assuming Axios instance is imported
import handleErrors from '../auth/handleErrors';
import withAuthCheck from '../hoc/withAuthCheck';
import Sidebar from '../utils/Sidebar.js';
import { getRandomColor } from '../utils/utils';
import { Bar } from 'react-chartjs-2';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Deprecated
const ViewSingleOpinionCampaign = () => {
  const [session, setSession] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pollResults, setPollResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [chartData, setChartData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const { sessionId, pollId } = useParams(); // Get sessionId and pollId from URL params
  const navigate = useNavigate();

  const fetchSessionDetails = useCallback(async () => {
    try {
      const response = await api.get(`/view_single_opinion_results/${sessionId}/${pollId}`); // Adjust to match your route
      const { session, pollResults } = response.data;

      setSession(session);
      setPollResults(pollResults);

      // Calculate total responses
      const total = pollResults.length;
      setTotalCount(total);

      // Prepare chart data (group results by answer)
      const answersDistribution = pollResults.reduce((acc, result) => {
        acc[result.answer] = (acc[result.answer] || 0) + 1;
        return acc;
      }, {});

      // Prepare chart data for Bar component
      /*setChartData({
        labels: Object.keys(answersDistribution),
        datasets: [
          {
            label: 'Number of Users',
            data: Object.values(answersDistribution),
            backgroundColor: 'blue' // No need to mark correct answer
          }
        ]
      });*/

      const backgroundColors = Object.keys(answersDistribution).map(() => getRandomColor());
      setChartData({
        labels: Object.keys(answersDistribution),
        datasets: [
          {
            label: 'Number of Users',
            data: Object.values(answersDistribution),
            backgroundColor: backgroundColors,  // Apply dynamic colors
          }
        ]
      });

    } catch (error) {
      await handleErrors(error, navigate, fetchSessionDetails, setErrorMessage);
    }
  }, [sessionId, pollId, navigate]); // Add sessionId and pollId as dependencies

  // Fetch session details on component mount and set interval to refresh data
  useEffect(() => {
    fetchSessionDetails(); // Initial fetch

    const intervalId = setInterval(() => {
      if (!errorMessage)
        fetchSessionDetails(); // Fetch every 1 second
    }, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [errorMessage, fetchSessionDetails, sessionId]);

  const handleLogout = async () => {
    try {
      await api.post('/logout'); // Call your backend logout endpoint
      localStorage.removeItem('access_token'); // Clear the access token from localStorage
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Opinion Poll Results</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
            </button>
            <button onClick={handleProfile}>
              <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
            </button>
          </div>
        </div>

        <h2>Opinion Poll Results</h2>

        {errorMessage && <p style={{ color: 'black' }}>{errorMessage}</p>}

        {session ? (
          <div>
            <p><strong>Timestamp of Session:</strong> {new Date(session.session_timestamp).toLocaleString()}</p>
            <p><strong>Session ID:</strong> {session.poll_session_id}</p>
            <p><strong>Number of Poll Users:</strong> {totalCount}</p>

            <div>
              <h3>Poll Result Chart</h3>
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Options',
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Number of Users',
                      },
                      ticks: {
                        precision: 0, // Ensure integer values
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <p>Loading session details...</p>
        )}
      </div>
    </div>
  );
};

export default withAuthCheck(ViewSingleOpinionCampaign);